// import React from 'react'
// import modernToDoImg from "../../assets/modern-to-do.png"
// import cryptoTrackerImg from "../../assets/crypto-tracker.png"
// import webToolsImg from "../../assets/web-tools.png"
// import digitalMarketingAgencyImg from '../../assets/digital-marketing-agency.png'
// import notesAppImg from '../../assets/notes-app.png'
// import chromeExtensionImg from '../../assets/chrome-extension.png'
import portfolio1Img from '../../assets/portfolio1.png'
import visionphoto from '../../assets/visionphoto.png'
import comingsoon from '../../assets/comingsoon.png'
import progress2Gif from '../../assets/progress2.gif'
import autogradecki from '../../assets/autogradecki.png'
import fesiwaldobra from '../../assets/festiwaldobra.png'

export const projectsInfo = [
    {
        src: portfolio1Img,
        alt: "WebPortfolio-Project",
        title: "Poftfolio-1.0",
        codeLink: true,
        live: "https://szymonwiewiorski.com/portfolio1.0",
        code: "https://github.com/ShyMi0/portfolio1.0" // # linki do projektów portfolio
    },
    {
        src: visionphoto,
        title: "PhotoFolio-Project",
        codeLink: false,
        alt: "VisionPhoto",
        live: "https://szymonwiewiorski.com/VisionPhoto-1.0/",
        code: "#"
    },
    {
        src: autogradecki,
        title: "Car Service",
        codeLink: false,
        live: "https://autogradecki.pl",
        code: "#"
    },
    {
        src: fesiwaldobra,
        alt: "Głos Ulicy - Festiwal Dobra",
        title: "Charity Concert",
        codeLink: false,
        live: "https://festiwaldobra.com",
    },
    {
        src: comingsoon,
        alt: "Fundacja Vanessa",
        title: "Comming Soon",
        codeLink: false,
        live: "https://fundacjavanessa.com/",
        code: "#"
    },
    {
        src: progress2Gif,
        alt: "Soon",
        title: "Soon",
        codeLink: true,
        live: "#",
        code: "#"
    },
]