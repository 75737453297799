import React from 'react'
import './Footer.scss'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container container">
                <h1 className="footer-title">Szymon Wiewiórski</h1>

                <ul className="footer-list">
                    <li>
                        <a href="#about" className="footer-link">About</a>
                    </li>
                    <li>
                        <a href="#projects" className="footer-link">Projects</a>
                    </li>
                    <li>
                        <a href="#resources" className="footer-link">Resources</a>
                    </li>
                </ul>

                <div className="footer-social">
                    <a href="https://github.com/ShyMi0" className='footer-social-link' target="_blank">
                        <i className="uil uil-github-alt"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/szymon-wiewiorski" className='footer-social-link' target="_blank">
                        <i className="uil uil-linkedin-alt"></i>
                    </a>
                    <a href="https://www.instagram.com/three.steps.closer/" className='footer-social-link' target="_blank">
                        <i className="uil uil-instagram"></i>
                    </a>
                </div>

                <span className="footer-copy">
                    &#169; Copyright © 2024 SW - All rights reserved
                </span>
            </div>
        </footer>
    )
}

export default Footer