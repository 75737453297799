import React from 'react'

const Social = () => {
    return (
        <div className="home-social">
            <a href="https://github.com/ShyMi0" className='home-social-icon' target="_blank">
                <i className="uil uil-github-alt"></i>
            </a>
            <a href="https://www.linkedin.com/in/szymon-wiewiorski" className='home-social-icon' target="_blank">
                <i className="uil uil-linkedin-alt"></i>
            </a>
            <a href="https://www.instagram.com/three.steps.closer/" className='home-social-icon' target="_blank">
                <i className="uil uil-instagram"></i>
            </a>
            <a href="https://www.facebook.com/szymon.wiewiorski.1" className='home-social-icon' target="_blank">
                <i className="uil uil-facebook-f"></i>
            </a>
            {/* <a href="https://twitter.com/PnescuCosmin1" className='home-social-icon' target="_blank">
                <i className="uil uil-twitter-alt"></i>
            </a> */}
        </div>
    )
}

export default Social