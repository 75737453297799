import React, { useEffect, useState } from 'react'
import './Resources.scss'
// import eBook from '../../assets/ebook1.png'
// import eBookPDF from '../../assets/Web-Development-eBook.pdf'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Resources = () => {
    useEffect(() => {
        AOS.init({
            duration: 1500,
        })
    }, []);

    return (
        <section className="resources section" id="resources">
            <h2 data-aos="fade-up" className="section-title">Resources</h2>
            <span data-aos="fade-up" className="section-subtitle">- Learn with me -</span>

            <div className="resources-container grid">
                <div data-aos="fade-up" className="resources-card">
                    <div className="resources-card-image">
                        {/* <img src={eBook} alt="Web Dev eBook" /> */}
                    </div>

                    <div className="resources-card-details">
                        <div className="resources-card-details-content">
                            <h3>More info soon...</h3>
                            {/* <p> &nbsp; &nbsp; &nbsp; &nbsp; Znajdź narzędzie do tworzenia stron internetowych */}
                            {/* Acest eBook to oferta, która zapewnia dokładne wsparcie dla deweloperów stron internetowych </p> */}
                            {/* <a href={eBookPDF} download="Web Development eBook" className="card-download" target="_blank">Download</a> */}
                        </div>
                    </div>
                </div>
                <div className="resources-coffe">
                    <h4>If You Like My Work...</h4>
                    <a href="https://www.buymeacoffee.com/shymi"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=shymi&button_colour=FFDD00&font_colour=000000&font_family=Poppins&outline_colour=000000&coffee_colour=ffffff" /></a>
                </div>
            </div>
        </section>
    )
}

export default Resources